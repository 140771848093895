.BackBox{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF80;
}

.BigBox{
    width: 300px;
    height: 312px;
    background-color: #ffffff;
    margin: auto;
    margin-top: 12%;
    display: flex;
    border-radius: 16px;
    box-shadow: 1px 4px 15px 0px #0000001F;
    padding: 30px;
    gap: 21px;
    flex-direction: column;
}

.TopButton{
    width: 240px;
    height: 24px;
    display: flex;
    gap: 10px;
    margin: auto;
    text-align: center;
    font-size: 20px;
    justify-content: center;
    font-weight: 700;
    line-height: 24px;
}
.TopButton p{
    color: #C25BFA;
    
}

.entekhab{
    color: #7606B2;
}
.off{
    color: #C25BFA;
}

.intabs{
    display: flex;
    flex-direction: column;
    gap: 15px
}
.InputPart{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.EmailVorod{
    width:240px ;
    height:38px ;
    border-radius: 8px;
    border: 2px solid #8806CE;
    padding:0px 10px 0px 0px ;
}
.EmailVorod::placeholder{
    color: #8806CE;
    opacity: 1;
    font-size: 16px;
}
.PassVorod{
    width:240px ;
    height:38px ;
    border-radius: 8px;
    border: 2px solid #8806CE;
    padding:0px 10px 0px 0px ;
}
.PassVorod::placeholder{
    color: #8806CE;
    opacity: 1;
    font-size: 16px;
}
:focus-visible{
    outline: none;
}
.Faramoshi{
    color: #C25BFA;
    font-size: 16px;
    font-weight: 600;
}

.Bekhaterbespor{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    color: #7606B2;
    font-size: 16px;
    font-weight: 600;
}
.Bekhaterbespor input[type=checkbox] {
    accent-color: #7606B2;
    width: 18px;
    height: 18px;
  }

  .VorodButt{
    width: 100%;
    background-color: #8806CE;
    height: 42px;
    font-size: 20px;
    font-weight: 700;
    color: #EDFAFD;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }







  .intabs2{
    display: flex;
    flex-direction: column;
    gap: 30px
  }
  .InputPart2{
    display: flex;
    flex-direction: column;
    gap: 13px;
}


.passboxsabtnam{
    display: flex;
    gap: 10px;
}

  .passsabtnam{
    width:114px ;
    height:38px ;
    border-radius: 8px;
    border: 2px solid #8806CE;
    padding:0px 10px 0px 0px ;
  }
  .passsabtnam::placeholder{
    color: #8806CE;
    opacity: 1;
    font-size: 16px;
}