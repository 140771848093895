.BBox {
}

.displaynone {
  display: none;
}

.TopBox {
  background-image: url(../../assets/img/Backdrop\ Art.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  direction: ltr;
}

@media screen and (max-width: 1000px) {
  .TopBox {
    background-image: url(../../assets/img/Backdrop\ Art.png);
  }
}

.TopBox h1 {
  width: 19%;
  direction: rtl;
  color: #3b0359;
  font-size: 264%;
}

.SearchBox {
  width: 375px;
  height: 60%;
  border-radius: 11px;
  background: rgba(255, 255, 255, 0.39);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.4px);
  -webkit-backdrop-filter: blur(13.4px);
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 50px 0px rgba(237, 250, 253, 0.25) inset,
    1px 4px 15px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.Chosdastebandi {
  width: 73%;
  height: 21%;
  font-size: 94%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #27023b;
  border-radius: 10px;
}

.leftIcon {
  padding-top: 1%;
  margin-left: 10px;
}

.rightIcon {
  padding-left: 6%;
}

.SearchDastebandi {
  width: 100%;
  display: flex;
  direction: ltr;
  flex-direction: row;
  font-size: 0.8rem;
  margin-right: 10px;
  justify-content: flex-end;
  align-items: center;
}

.SearchOpenBox1 {
  position: absolute;
  top: 33%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding: 2.5%;
  width: 64%;
  height: 68%;
  overflow: auto;
  gap: 5%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.dataListBox1 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  cursor: pointer;
}
.eachDataItem {
  width: 90%;
  padding-right: 5px;
  color: #3c045b;
  font-weight: bold;
  cursor: pointer;
}

.eachDataItem:hover {
  border-radius: 4px;
  background: var(
    --60,
    linear-gradient(0deg, #f0d6fe 0%, #f0d6fe 100%),
    #dcf5fa
  );
}

.SearchOpenBox2 {
  position: absolute;
  top: 59%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding: 2.5%;
  overflow: auto;
  width: 64%;
  height: 68%;
  gap: 5%;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.SearchOpenBox3 {
  position: absolute;
  top: 90%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding: 2.5%;
  width: 64%;
  height: 68%;
  overflow: auto;
  gap: 5%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.clearSearchBox {
  padding: 5px 10px;
  background-color: #3b0359;
  color: white;
  font-size: 1.1rem;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.inputf {
  border: none;
  height: 20px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 15px 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.inputf > input::placeholder {
  color: #7303af;
  opacity: 1;
  font-weight: bold;
  background-color: transparent !important;
}

.searchIcon {
  position: absolute;
  left: 15px;
  cursor: pointer;
}

.searchdown {
  position: absolute;
  top: 80%;
  left: -10%;
  font-size: 35px;
  color: white;
  background-color: #3b0359;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
span b {
  font-size: 116%;
}
span i {
  font-size: 116%;
}

.inputf::placeholder {
}

/* down part start */
.DownBox {
  margin-bottom: 100px;
}

.DownBBox {
  width: 90%;
  margin: auto;
  margin-top: 133px;
}

.uptitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.ShowPart {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 301px;
  font-size: 16px;
  font-weight: 600px;
  color: #27023b;
}

.pishnahadT {
  font-size: 40px;
  font-weight: bold;
  color: #27023b;
}

.textP {
  display: flex;
  gap: 7px;
  background-color: #f7ebfe;
  border-radius: 8px;
  padding: 3px;
}

.pishnahadat {
  cursor: pointer;
  padding: 4px 8px 4px 8px;
}

.pishnahadat2 {
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  border-left: 2px solid #8806ce;
}

.pishnahadat4 {
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  /* border-left: 2px solid #8806ce; */
}
.pishnahadat3 {
  background-color: #8806ce;
  color: #ffffff;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
}

.mahsolatS {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.TopPartOfDownB {
  display: flex;
  position: relative;
  gap: 2px;
  padding: 12px;
  width: 249px;
  box-sizing: border-box;
  height: 346px;
  flex-direction: column;
}
.downPartt {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.TopPartOfDownB:hover {
  padding: 10px;
  border: 2px solid #e1adfc;
  border-radius: 16px;
}

.TopPartOfDownB:hover .LikeBox {
  display: flex;
  transition: 5s;
}

.TopPartOfDownB h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
}

.TopPartOfDownB p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
}

.LikeBox {
  box-sizing: border-box;
  position: absolute;
  top: 23px;
  display: none;
  gap: 8px;
  left: 23px;
  font-size: 26px;
  background-color: #ffff;
  border-radius: 8px;
  color: #8806ce;
  flex-direction: column;
  padding: 2px 3px 5px;
  box-sizing: border-box;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.SearchOpenBox1::-webkit-scrollbar,
.SearchOpenBox2::-webkit-scrollbar,
.SearchOpenBox3::-webkit-scrollbar,
.dataListBox1::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.SearchOpenBox1::-webkit-scrollbar-track,
.SearchOpenBox2::-webkit-scrollbar-track,
.SearchOpenBox3::-webkit-scrollbar-track,
.dataListBox1::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background-color: linear-gradient(0deg, #f7ebfe 0%, #f7ebfe 100%);
  /* background-color: linear-gradient(0deg, #F7EBFE 0%, #F7EBFE; */
  border-radius: 10px;
}

/* Handle */
.SearchOpenBox1::-webkit-scrollbar-thumb,
.SearchOpenBox2::-webkit-scrollbar-thumb,
.SearchOpenBox3::-webkit-scrollbar-thumb,
.dataListBox1::-webkit-scrollbar-thumb {
  background: #a409f7;
  border-radius: 10px;
}

/* Handle on hover */
.SearchOpenBox1::-webkit-scrollbar-thumb:hover,
.SearchOpenBox2::-webkit-scrollbar-thumb:hover,
.SearchOpenBox3::-webkit-scrollbar-thumb:hover,
.dataListBox1::-webkit-scrollbar-thumb:hover {
  background: #a409f7;
}
.LikeBox i {
  font-size: 23px;
  cursor: pointer;
}

.LinkSBox {
  display: flex;
  justify-content: space-between;
  color: #8806ce;
}

.LinkSBox a {
  display: flex;
  align-items: center;
  gap: 2px;
}

@media screen and (max-width: 700px) {
  .SearchBox {
    width: 70%;
  }
  .pishnahadT {
    font-size: 15px;
  }
  .ShowPart {
    width: 200px;
  }
  .textP {
    font-size: 8px;
  }
}
