.Header {
  width: 100%;
  height: min-content;
  display: flex;
  direction: rtl;
  justify-content: space-evenly;
  align-items: center;
  color: #27023b;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.topologo {
  display: flex;
  font-size: 24px;
  color: #27023b;
  font-weight: 700;
}

.topologo img {
  width: 5rem;
  height: 5rem;
}

.Menubox {
  display: flex;
  justify-content: space-between;
  width: 190px;
  height: 72px;
  color: #27023b;
  gap: 20px;
}

.ItemsMenubox {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.ItemsMenubox:hover {
  border-bottom: 2px solid #3b0359;
}

.input {
  background-color: #f7ebfe;
  width: max-content;
  min-width: 350px;
  position: relative;
  height: 42px;
  padding: 14px 25px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  flex: 3;
}

.input::placeholder {
  color: #27023b;
  font-size: 18px;
  opacity: 1;
  font-weight: bold;
}

.searchBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f7ebfe;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
}

.Sabtnam {
  display: flex;
  justify-content: space-around;
  width: auto;
  height: auto;
  gap: 10px;
  align-items: center;
  /* background-color: aquamarine; */
}

.vorodicon {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 3px;
  gap: 5px;
}

.openmahsolat {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.71);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.1px);
  -webkit-backdrop-filter: blur(13.1px);
  position: absolute;
  min-width: 650px;
  min-height: 300px;
  top: 72px;
  display: flex;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-bottom: 7px;
  padding-right: 8px;
  z-index: 100;
}

.ProductsMenu {
  position: relative;
}

.DisplayNone {
  position: absolute;
  display: none;
}

.RightBox {
  width: auto;
  z-index: 100;
  margin-right: 10px;
  flex: 1;
}

.RightBox button {
  height: 100%;
  background-color: #ffffff00;
  border: none;
  color: #3b0359;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
}

.RightBox h2 {
  position: relative;
  padding: 10px 0px;
}

.LeftIcon {
  position: absolute;
  margin-top: 0%;
  align-items: center;
  top: 0;
  left: 0;
  font-size: 86%;
  padding-top: 10%;
}

.LeftBox {
  flex: 2;
  z-index: 100;
  flex-wrap: nowrap;
  width: auto;
}

.leftOpen h5 i {
  padding: 0 6% 0 0;
  display: inline-block;
}

.leftOpen h5 {
  padding-top: 3%;
}

.leftOpen {
  margin-right: 6%;
}

.SBox {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 20px;
  height: auto;
  width: auto;
}

.SBox a {
  direction: rtl;
  padding-right: 10px;
}

.SBox h4 {
  margin-bottom: 2%;
  font-size: 18px;
}

@media screen and (max-width: 970px) {
  .input {
    min-width: max-content;
  }

  .Header {
    justify-content: space-between;
  }

  .topologo {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .searchBox {
    display: none;
  }
  .Header {
    justify-content: space-between;
    padding: 1.5rem;
  }
}
