.TopPartOfDownB {
  display: flex;
  position: relative;
  gap: 7px;
  padding: 12px;
  width: 249px;
  box-sizing: border-box;
  flex-direction: column;
  cursor: default;
  border: 2px solid white;
  border-radius: 16px;
}
.downPartt {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.TopPartOfDownB:hover {
  /* padding: 10px; */
  border: 2px solid #e1adfc;
  border-radius: 16px;
}

.TopPartOfDownB:hover .LikeBox {
  display: flex;
  transition: 5s;
}

.TopPartOfDownB h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
}

.TopPartOfDownB p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
}

.LikeBox {
  box-sizing: border-box;
  position: absolute;
  top: 23px;
  display: none;
  gap: 8px;
  left: 23px;
  font-size: 26px;
  background-color: #ffff;
  border-radius: 5px;
  color: #8806ce;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  align-items: center;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.LikeBox i {
  font-size: 23px;
  cursor: pointer;
}

.LinkSBox {
  display: flex;
  justify-content: space-between;
  color: #8806ce;
}

.LinkSBox a {
  display: flex;
  align-items: center;
  gap: 2px;
}
