@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Markazi Text" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inputPlaceColorized::placeholder {
  color: blueviolet;
  font-size: 20px;
  font-weight: bold;
  vertical-align: top;
  text-align: start;
}

.customGlass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.83);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.1px);
  -webkit-backdrop-filter: blur(13.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.inputPlaceColorized {
  vertical-align: top;
  text-align: start;
  font-size: 20px;
  direction: rtl;
}

.myBackdrop {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

ul li {
  list-style-type: circle;
}